@import '../imports.scss';

.titleSection{
  text-align: center;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;

  h1{
    font-family: $font-heading;
  }

  p{
    font-family: $font-text;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8em;
  }
}

.menu{
  background: $color-white;
  //position: sticky;
  //top: 0;
  padding: 50px 0 0 0;
  //z-index: 10;

  ul{
    max-width: 100%;
    width: 800px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-flow: row nowrap;

    li{
      width: 150px;
      margin-right: 20px;
      cursor: pointer;

      &.active{
        border-bottom: 1px solid $color-black;
      }

      img{
        max-width: 100%;
        width: 60px;
        display: block;
        margin: 0 auto;
      }

      p{
        display: block;
        text-align: center;
        color: $color-black;
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
        line-height: 1.5em;
      }
    }
  }
}

.faqSection{
  padding-top: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}


.faqItemTitle{
  max-width: 100%;
  width: 800px;
  margin: 0 auto 30px;
}

.faqItem{
  margin-bottom: 30px;
  max-width: 100%;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.faqQuestion{
  font-weight: bold;
  font-family: $font-text;
  font-size: 18px;
  margin-bottom: 10px;
}

.faqAnswer{
  font-weight: normal;
  line-height: 1.5em;
  color: $color-black;

  p{
    line-height: inherit;
    margin-bottom: 10px;
    color: $color-black;
  }

  ul, ol{
    margin-top: 10px;
    margin-left: 30px;
    padding-left: 0;

    li{
      display: block;
      position: relative;
      margin-bottom: 8px;

      &:before{
        position: absolute;
        top: 8px;
        left: -18px;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $color-black;
      }

      ul{
        li{
          &:before{
            position: absolute;
            top: 8px;
            left: -18px;
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            border: 1px solid $color-black;
            background: transparent;
          }
        }
      }
    }
  }
}