@import '../../imports.scss';

.fixedBar{
  position: sticky;
  top: 0;
  z-index: 10;
  background: $color-black;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: $color-white;
  padding: 20px 30px;
  font-size: 24px;
  font-family: $font-heading;
  font-weight: bold;
  text-transform: uppercase;

  @include mobile(){

  }

  .productName{
    font-size: 18px;
  }

  .productPrice{
    display: flex;
    align-items: center;

    .nominal{
      @include mobile(){
        display: none;
      }
    }

    button{
      display: inline-block;
      background: $color-black;
      color: $color-white;
      border: 1px solid $color-white;
      text-transform: uppercase;
      font-size: 14px;
      padding: 10px 30px;
      font-weight: bold;
      margin-left: 20px;
      white-space: nowrap;
    }
  }

}