@import "../imports";

.formItem{
  margin: 15px 0;
}

.sectionTitle{
  text-align: center;
  margin-bottom: 30px;
  font-size: 21px;
  font-weight: 500;
}



.chooseShippingTypeWrapper{
  text-align: center;
  margin-bottom: 100px;
  min-height: 20vh;
}

.chooseShippingTypeActions{
  text-align: center;

  button{
    margin: 10px;
  }
}


.chooseAddressActions{
  text-align: center;
  margin-top: 50px;

  button{
    margin: 10px;
  }
}

.userAddressList{
  margin: 20px 0;
}

.userAddressItem{
  position: relative;
  padding: 20px 30px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  max-width: 100%;
  width: 500px;
  margin-bottom: 20px;

  i{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: $color-primary;
  }

  &.active{
    background: #FAFAFA;
  }

  &:hover{
    background: #FAFAFA;
  }
}

.userAddressLabel{
  font-size: 16px;
  font-weight: 600;
}

.userAddressName{
  margin: 5px 0;
}

.userAddressNotes{
  margin-top: 5px;
}



.shippingMethodList{
  margin: 30px 0;
  text-align: center;
}

.shippingMethodItem{
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 30px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  max-width: 100%;
  width: 500px;
  margin-bottom: 20px;

  i{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: $color-primary;
  }

  &.active{
    background: #FAFAFA;
  }

  &:hover{
    background: #FAFAFA;
  }
}

.shippingMethodName{
  font-size: 16px;
  font-weight: 500;
}

.shippingMethodService{
  margin: 5px 0;
}

.shippingMethodPrice{
  font-size: 18px;
  font-weight: 500;
}

.shippingMethodNotes{
  margin-top: 5px;
}


.showroomList{
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  margin-bottom: 50px;
}

.showroomListActions{
  text-align: center;

  button{
    margin: 10px;
  }
}

.showroomRegionName{
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.showroomItem{
  position: relative;
  width: 350px;
  max-width: 100%;
  padding: 20px 25px 70px 25px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover{
    box-shadow: 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
  }
}

.showroomName{
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.showroomAddress{
  margin-bottom: 0;
  line-height: 1.7em;
}

.showroomActions{
  position: absolute;
  right: 25px;
  bottom: 20px;
  text-align: right;

  a{
    display: inline-block;
    margin-left: 20px;
  }
}

.showroomSelectButton{
  //position: absolute;
  //bottom: 10px;
  //right: 10px;
  position: absolute;
  left: 25px;
  bottom: 20px;
  padding: 5px 20px;
  margin-top: 15px;
  outline: none;
  border: 0;
  background: $color-primary;
  color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.cartTable{
  @include mobile(){
    display: none;
  }

  th{
    font-family: "Open Sans", sans-serif;
  }
}

.cartTableMobile{
  display: none;

  @include mobile(){
    display: block;
  }
}

.mobileCartItem{
  display: none;

  @include mobile(){
    display: block;
  }

  td{
    @include mobile(){
      display: block !important;
      text-align: left;
    }
  }

  .productImage{
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: inline-block;
    vertical-align: baseline;
    margin-top: 20px;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .productRight{
    display: inline-block;
    width: calc(100% - 100px);
    text-align: left;
    padding: 20px;

    .productName{
      display: block;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .productSize{
      display: block;
      font-size: 16px;
      text-transform: none;
      margin-bottom: 5px;
    }

    .productPrice{
      font-size: 16px;
    }
  }

  .productOptions{
    text-align: left;

    .productQty{
      text-align: left;
      display: inline-block;
    }

    .productGrandPrice{
      text-align: right;
      margin-top: 10px;
      font-size: 16px;
      color: #222;
      font-weight: bold;
    }

    .removeBtn{
      text-align: right;
      margin: 10px 0 20px 0;

      a{
        justify-content: flex-end;
      }
    }
  }
}

.footerTable{
  td{
    font-size: 16px;
  }

  td:last-child{
    width: 300px;
    white-space: nowrap;

    @include mobile(){
      width: 200px;
    }

    h2{
      @include mobile(){
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  @include mobile(){
    td{
      padding-top: 20px !important;

      &:last-child{
        padding-right: 0;
      }
    }
  }
}

.promoCode{
  text-align: right;
  margin-top: 20px;

  input{
    width: 200px;
    max-width: 100%;
    height: 50px;
    font-size: 18px;
    vertical-align: bottom;
    margin-right: 10px;
  }
}

.paymentArea{
  text-align: right;
  margin: 35px 0;
  //display: flex;
  //flex-flow: row nowrap;

  @include mobile(){
    margin: 30px 0;
  }

  //& > div{
  //  width: 50%;
  //}
  //
  //& > div:first-child{
  //  text-align: left;
  //}
  //
  //& > div:last-child{
  //  text-align: right;
  //}

  button{
    @include mobile(){
      display: block;
      width: 100%;
    }
  }

  h4{
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.paymentMethodItem{
  width: 200px;
  max-width: 100%;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  padding: 20px;
  cursor: pointer;
  text-align: center;
  border-top: 1px solid rgba(50,50,50,0.1);

  &:hover{
    box-shadow: 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
  }

  img{
    width: 150px;
    max-width: 80%;
  }
}

.summaryItems{
  display: flex;
}

.summaryItem{
  display: flex;
  flex-flow: row nowrap;

  .productImage{
    width: 150px;

    img{
      width: 150px;
      height: 150px;
      object-fit: cover;
      object-position: top;
      max-width: 100%;
    }
  }
}