@import '../../imports.scss';

.cartTable{
  @include mobile(){
    display: none;
  }

  th{
    font-family: "Open Sans", sans-serif;
  }

  :global(.table-head){
    @include mobile(){
      display: none;
    }
  }
}

.removeBtn{
  a{
    color: $color-primary !important;
    text-transform: none !important;
  }
}

.cartTableMobile{
  display: none;

  @include mobile(){
    display: block;
  }
}

.desktopCartItem{
  @include mobile(){
    display: none;
  }
}

.mobileCartItem{
  display: none;

  @include mobile(){
    display: block;
  }

  td{
    @include mobile(){
      display: block !important;
      text-align: left;
    }
  }

  .productImage{
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: inline-block;
    vertical-align: baseline;
    margin-top: 20px;

    img{
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: top;
    }
  }

  .productRight{
    display: inline-block;
    width: calc(100% - 100px);
    text-align: left;
    padding: 20px;

    @include mobile(){
      vertical-align: top;
    }

    .productName{
      display: block;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .productSize{
      display: block;
      font-size: 16px;
      text-transform: none;
      margin-bottom: 5px;
    }

    .productPrice{
      font-size: 16px;
    }
  }

  .productOptions{
    text-align: left;

    .productQty{
      text-align: left;
      display: inline-block;
    }

    .productGrandPrice{
      float: right;
      margin-top: 10px;
      font-size: 16px;
      color: #222;
      font-weight: bold;
    }

    .removeBtn{
      text-align: right;
      margin: 10px 0 20px 0;

      a{
        justify-content: flex-end;
      }
    }
  }
}

.footerTable{
  @include mobile(){
    td{
      padding-top: 20px !important;

      &:last-child{
        padding-right: 0;
      }
    }
  }
}

.cartButtons{
  @include mobile(){
    display: none;
  }
}

.productThumb{
  img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: top;
  }
}

.mobileCartButtons{
  display: none;

  @include mobile(){
    display: block;
  }

  a{
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  a.continueShoppingBtn{
    background: white;
    color: #222 !important;
  }
}