@import '../imports.scss';

.serviceSection{
  border: 0;

  @include mobile(){
    padding-top: 50px;
    border: 0;
  }

  h4{
    color: $color-black;
  }
}

.newsletterWrapper{
  margin-top: 50px;

  @include mobile(){
    margin-bottom: 60px;
  }

  button{
    @include mobile(){
      display: block;
      width: 150px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.sectionTitle{
  font-family: $font-heading;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: $color-black;

  @include mobile(){
    margin-bottom: 5px;
  }
}

.newsletterInput{
  border: 0;
  outline: none;
  background: transparent;
  text-align: center;
  display: block;
  width: 500px;
  max-width: 80%;
  border-bottom: 2px solid rgba(0,0,0,0.5);
  margin: 50px auto 30px auto;
  padding-bottom: 5px;
  font-size: 18px;
}

.newsletterForm{
  text-align: center;
}

.storeWrapper{
  margin-top: 80px;
  margin-bottom: 50px;
}

.storeIcon{
  margin-top: 0;
  text-align: center;
}

.playstoreImg{
  width: 200px;
  max-width: 80%;
  margin-top: 15px;

  @include mobile(){
    width: auto;
    height: 60px;
  }
}

.appstoreImg{
  width: 160px;
  max-width: 80%;
  margin-top: 15px;

  @include mobile(){
    width: auto;
    height: 41px;
  }
}