@import '../../../../imports.scss';

.size{
  border: 1px solid rgba(0,0,0,0.2) !important;
  border-radius: 0 !important;
  background: $color-black;

  a{
    font-size: 14px !important;
    transition: none;
    color: $color-white !important;
  }
}

.selectedSize{
  border: 1px solid $color-primary !important;
  background: $color-primary;

  a{
    color: white !important;
    cursor: default;
  }
}

.disabledSize{
  border: 1px solid lightgray !important;
  background: lightgray;

  a{
    cursor: default;
  }
}

.productDescription{
  @include mobile(){
    text-align: left;
  }
}

.productActionButtons{
  @include mobile(){
    .separator{
      display: none;
    }

    a{
      display: block;
      margin-bottom: 10px;
    }

    button{
      display: block;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.sectionTitle{
  @include mobile(){
    text-align: center;
  }
}

.productShare{
  @include mobile(){
    text-align: center;

    & > div{
      text-align: center !important;
    }
  }
}