@import '../imports.scss';

.titleSection{
  text-align: center;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;

  h1{
    font-family: $font-heading;
  }

  p{
    font-family: $font-text;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8em;
  }
}

.tncSection{
  padding-top: 30px;
}

.tncItem{
  margin-bottom: 30px;
  max-width: 100%;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}